<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0 mt-2">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->

          <!-- Search -->
          <b-col cols="12" md="6">
            <b-button v-b-modal.modal-prevent-closing>Manager Role</b-button>
            <b-modal
              id="modal-prevent-closing"
              ref="modal"
              size="lg"
              title="Submit Your Name"
              @show="resetModal"
              @hidden="resetModal"
              @ok="addRole"
            >
              <template #modal-header="{ close }">
                <h5>Manage Role</h5>
                <b-button size="sm" variant="outline-danger" @click="close()">
                  <feather-icon class="cursor-pointer" icon="XIcon" />
                </b-button>
              </template>
              <b-list-group>
                <b-list-group-item>
                  <b-row>
                    <b-col cols="2"> # </b-col>
                    <b-col cols="4"> User Role </b-col>
                    <b-col cols="6"> Action </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item
                  v-for="(role, index) in roles"
                  :key="role.id"
                >
                  <b-row>
                    <b-col cols="2">
                      {{ ++index }}
                    </b-col>
                    <b-col cols="4">
                      {{ role.name }}
                    </b-col>
                    <b-col cols="6">
                      <b-button
                        v-if="role.id > 5"
                        variant="danger"
                        size="sm"
                        @click="removeRole(role)"
                      >
                        <span class="text-nowrap">Remove</span>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>
              <form ref="form" @submit.stop.prevent="addRole" class="mt-1">
                <b-form-group
                  label="Role Name"
                  label-for="name-input"
                  invalid-feedback="Name is required"
                  :state="nameState"
                >
                  <b-form-input
                    id="name-input"
                    v-model="name"
                    :state="nameState"
                    required
                  ></b-form-input>
                </b-form-group>
              </form>
              <template #modal-footer="{ ok, cancel }">
                <b-button size="sm" variant="success" @click="ok()">
                  Save
                </b-button>
                <b-button size="sm" variant="danger" @click="cancel()">
                  Cancel
                </b-button>
              </template>
            </b-modal>
          </b-col>
        </b-row>
      </div>
    

    <app-collapse >
      <app-collapse-item :title="role.name+' ( '+role.users_count+' Members )'" v-for="role in roles" :key="role.id" class="rounded-0">
        <b-card no-body class="border">
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon icon="LockIcon" size="18" />
              <span class="align-middle ml-50">Module Permissions</span>
            </b-card-title>
          </b-card-header>

          <table class="table">
            <thead class="thead-light">
              <tr>
                <th scope="col">Module</th>
                <th scope="col">ADD</th>
                <th scope="col">UPDATE</th>
                <th scope="col">VIEW</th>
                <th scope="col">ACCESS</th>
                <th scope="col">DELETE</th>
                <th scope="col">ALL</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="module in modules" :key="module.id">
                <th scope="row" class="text-capitalize">
                  {{ module.module_name }}
                </th>
                
                <td
                >
                  <b-form-checkbox
                    v-for="permission in module.permissions"
                    :key="permission.id"
                    switch
                    :checked="roleHasPermission(role, permission)"
                    inline
                    @change="assignPermission(role, permission)"
                    v-if="permission.name.includes('_create')"
                  >
                  </b-form-checkbox>
                </td>
                <td
                >
                  <b-form-checkbox
                    v-for="permission in module.permissions"
                    :key="permission.id"
                    switch
                    :checked="roleHasPermission(role, permission)"
                    inline
                    @change="assignPermission(role, permission)"
                    v-if="permission.name.includes('_edit')"
                  >
                  </b-form-checkbox>
                </td>
                <td
                >
                  <b-form-checkbox
                    v-for="permission in module.permissions"
                    :key="permission.id"
                    switch
                    :checked="roleHasPermission(role, permission)"
                    inline
                    @change="assignPermission(role, permission)"
                    v-if="permission.name.includes('_show')"
                  >
                  </b-form-checkbox>
                </td>
                <td
                >
                  <b-form-checkbox
                    v-for="permission in module.permissions"
                    :key="permission.id"
                    switch
                    :checked="roleHasPermission(role, permission)"
                    inline
                    @change="assignPermission(role, permission)"
                    v-if="permission.name.includes('_access')"
                  >
                  </b-form-checkbox>
                </td>
                <td
                >
                  <b-form-checkbox
                    v-for="permission in module.permissions"
                    :key="permission.id"
                    switch
                    :checked="roleHasPermission(role, permission)"
                    inline
                    @change="assignPermission(role, permission)"
                    v-if="permission.name.includes('_delete')"
                  >
                  </b-form-checkbox>
                </td>
                <td
                >
                  <b-form-checkbox

                    switch
                    :checked="calculateGroupStatus(role, module)"
                    inline
                    @change="toggleGroupPermission(role, module,!calculateGroupStatus(role, module))"
                  >
                  </b-form-checkbox>
                </td>
              </tr>
            </tbody>
          </table>
        </b-card>
        <b-card no-body class="border mt-1" v-if="general_settings && general_settings.permissions">
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon icon="LockIcon" size="18" />
              <span class="align-middle ml-50">General Permissions</span>
            </b-card-title>
          </b-card-header>

          <b-row class="ml-1 mb-1">
            <b-col cols="4" v-for="permission in general_settings.permissions">
                
              <th scope="row" class="text-capitalize">
                {{ snakeCaseToTitleCase(permission.name)}}
              </th>
              <b-form-checkbox 
                :key="permission.id"
                switch
                :checked="roleHasPermission(role, permission)"
                inline
                @change="assignPermission(role, permission)"
              >
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-card>
      </app-collapse-item>
    </app-collapse>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormInput,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  VBTooltip,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { onUnmounted, watch, ref } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import permissionStoreModule from "../permissionStoreModule";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BPagination,
    AppCollapse,
    AppCollapseItem,
    vSelect,
    /* eslint-disable */
    ToastificationContent,
    /* eslint-enable */
    BFormCheckbox,
    BFormCheckboxGroup,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  setup() {
    const REFERRAL_APP_STORE_MODULE_NAME = "app-role-permission";

    // Register module
    if (!store.hasModule(REFERRAL_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        REFERRAL_APP_STORE_MODULE_NAME,
        permissionStoreModule
      );
    }
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REFERRAL_APP_STORE_MODULE_NAME))
        store.unregisterModule(REFERRAL_APP_STORE_MODULE_NAME);
    });

    return {};
  },
  data() {
    return {
      roles: [],
      modules: [],
      general_settings:[],
      name: "",
      nameState: null,
    };
  },
  created() {
    this.fetchRolesList();
  },
  methods: {
    calculateGroupStatus(role,module){
      var modulePermissionLength = 0;
      for (let index = 0; index < module.permissions.length; index++) {
        const permission = module.permissions[index];
        if(role.permissions.includes(permission.name)){
          modulePermissionLength +=1;
        }
      }
      if(modulePermissionLength == module.permissions.length){
        return true;
      }
      return false;
    },
    toggleGroupPermission(role,module,state){
      var permissions = [];
      for (let index = 0; index < module.permissions.length; index++) {
        const permission = module.permissions[index];
          permissions.push(permission.name);
      }
      if(state){
        var action = 'add';
      }
      else{
        var action = 'remove';
      }
      this.assignGroupPermission(role,permissions,action);
      this.fetchRolesList();      
    },
    snakeCaseToTitleCase(str) {
      // Split the string into words based on the underscore ('_') delimiter
      const words = str.split('_');

      // Capitalize the first letter of each word and make the rest lowercase
      const titleCaseWords = words.map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      });

      // Join the words back into a string
      const titleCaseStr = titleCaseWords.join(' ');

      return titleCaseStr;
    },
    fetchRolesList() {
      const self = this;
      this.$store
        .dispatch("app-role-permission/fetchRoles")
        .then((res) => {
          if (res.data.success) {
            this.roles = res.data.data.roles;
            this.modules = res.data.data.modules;
            this.general_settings = res.data.data.general_settings;

          } else {
            // self.$toast(
            //   {
            //     component: ToastificationContent,
            //     props: {
            //       title: "Get Role list Faild",
            //       icon: "BellIcon",
            //       variant: "danger",
            //       text: res.data.message,
            //     },
            //   }
            // );
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Get Role list Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text:res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    roleHasPermission(role, permission) {
      return role.permissions.includes(permission.name);
    },
    assignPermission(role, permission) {
      const self = this;
      let params = [];
      params.id = role.id;
      params.data = permission;
      this.$store
        .dispatch("app-role-permission/updateRolePermissions", params)
        .then((res) => {
          if (res.data.success) {
            // self.$toast(
            //   {
            //     component: ToastificationContent,
            //     props: {
            //       title: "Permission Updated",
            //       icon: "BellIcon",
            //       variant: "success",
            //     },
            //   }
            // );
            this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Permission Updated',
          icon: 'BellIcon',
          variant: 'success',
          hideClose: true,
        },
      }, {
        timeout: 2000, 
        position: 'bottom-center',
        toastClassName:"successful-info",
        hideProgressBar : true,
      }); 
            this.fetchRolesList(); 
          } else {
            // self.$toast(
            //   {
            //     component: ToastificationContent,
            //     props: {
            //       title: "Permission Updated",
            //       icon: "BellIcon",
            //       variant: "danger",
            //       text: res.data.message,
            //     },
            //   }
            // );
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Permission Updated',
                icon: 'BellIcon',
                variant: 'danger',
                text:  res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    assignGroupPermission(role, permissions,state) {
      const self = this;
      let params = [];
      params.id = role.id;
      params.data = {
        permissions:permissions,
        permission_state:state,
      }
      this.$store
        .dispatch("app-role-permission/updateRoleGroupPermissions", params)
        .then((res) => {
          if (res.data.success) {
            // self.$toast(
            //   {
            //     component: ToastificationContent,
            //     props: {
            //       title: "Permission Updated",
            //       icon: "BellIcon",
            //       variant: "success",
            //     },
            //   }
            // );
            this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Permission Updated',
          icon: 'BellIcon',
          variant: 'success',
          hideClose: true,
        },
      }, {
        timeout: 2000, 
        position: 'bottom-center',
        toastClassName:"successful-info",
        hideProgressBar : true,
      }); 
          } else {
            // self.$toast(
            //   {
            //     component: ToastificationContent,
            //     props: {
            //       title: "Permission Updated",
            //       icon: "BellIcon",
            //       variant: "danger",
            //       text: res.data.message,
            //     },
            //   }
            // );
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Permission Updated',
                icon: 'BellIcon',
                variant: 'danger',
                text:  res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    addRole() {
      const self = this;
      let params = [];
      params.name = self.name;
      this.$store
        .dispatch("app-role-permission/addRole", params)
        .then((res) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Role Added",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Role Added',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          this.fetchRolesList();
        })
        .catch((error) => {
          // this.$toast(
          //   {
          //     component: ToastificationContent,
          //     props: {
          //       title: "Oops! Role Adding Failed",
          //       icon: "BellIcon",
          //       variant: "danger",
          //       text: error,
          //     },
          //   });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Role Adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    resetModal() {
      this.name = "";
    },
    removeRole(role) {
      const self = this;
      let params = [];
      params.id = role.id;
      this.$store
        .dispatch("app-role-permission/removeRole", params)
        .then((res) => {
          // self.$toast(
          //   {
          //     component: ToastificationContent,
          //     props: {
          //       title: "Role Removed",
          //       icon: "BellIcon",
          //       variant: "success",
          //       text: res.data.message,
          //     },
          //   });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Role Removed',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          this.fetchRolesList();
        })
        .catch((error) => {
          // this.$toast(
          //   {
          //     component: ToastificationContent,
          //     props: {
          //       title: "Oops! Role Removing Failed",
          //       icon: "BellIcon",
          //       variant: "danger",
          //       text: error,
          //     },
          //   });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Role Removing Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text:error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    openWindow(link) {
      window.open(link);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
